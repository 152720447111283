<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showcaution"
        max-width="800px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span>{{ formTitle }}</span>
            <v-spacer></v-spacer>
            <v-text-field
              autocomplete="off"
              v-model="editedItem.statut_name"
              label="Statut"
              readonly
              @focus="$event.target.select()"
            ></v-text-field>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-if="editedItem.id > 0"
                  @click.stop="drawer = !drawer"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-clipboard-text-clock-outline </v-icon>
                </v-btn>
              </template>
              <span>Historique</span>
            </v-tooltip>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-tabs v-model="tabs" fixed-tabs class="elevation-1">
                <v-tabs-slider></v-tabs-slider>
                <v-tab href="#1" key="1"> Caution </v-tab>
                <v-tab
                  href="#2"
                  key="2"
                  v-if="
                    editedItem.id != -1 &&
                    ($store.state.auth.includes('01061') ||
                      $store.state.isadmin)
                  "
                >
                  Documents Attachés
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabs">
                <v-tab-item :value="'1'">
                  <v-container>
                    <v-row dense>
                      <v-col cols="12" sm="6" md="4">
                        <v-autocomplete
                          dense
                          :items="cautions_type"
                          v-model="editedItem.type_id"
                          item-text="libelle"
                          item-value="id"
                          label="Type de Caution"
                          :readonly="!modify"
                          :rules="[(v) => !!v || 'Type de Caution obligatoire']"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="4"> </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          autocomplete="off"
                          v-model="editedItem.ref_ca"
                          label="N° Caution"
                          :readonly="!modify"
                          :rules="[(v) => !!v || 'N° Caution obligatoire']"
                          @focus="$event.target.select()"
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="8">
                        <cursorselect
                          :Qsearch="tierscursor"
                          :Qresp="'tiers_cursor'"
                          :value="editedItem.tier_id"
                          :text_fields="[
                            'code_client',
                            'code_fournisseur',
                            'nom',
                          ]"
                          :variableadd="variableadd"
                          :item_text="'tier_name'"
                          @change="tier_change"
                          :label="tiertype == 1 ? 'Client' : 'Fournisseur'"
                          :readonly="!modify"
                          :key="cs"
                        >
                        </cursorselect>
                      </v-col>

                      <v-col cols="12" sm="4" md="4">
                        <v-autocomplete
                          v-if="editedItem.type_id != 1"
                          :items="cmds_tier"
                          v-model="editedItem.cmd_id"
                          :item-text="
                            (item) =>
                              item.nocmd +
                              (item.cmd_ref ? ' (' + item.cmd_ref + ')' : '') +
                              (item.label ? ' - ' + item.label : '')
                          "
                          item-value="id"
                          :label="'Contrat'"
                          :readonly="!modify"
                          :rules="[(v) => !!v || 'Contrat obligatoire']"
                          @change="cmd_change"
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <datepicker
                          label="
                          Date Caution
                        "
                          v-model="editedItem.date_caution"
                          :rules="[(v) => !!v || 'Date Caution obligatoire']"
                          :edit="modify"
                        ></datepicker>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <datepicker
                          label="
                          Date Validite
                        "
                          v-model="editedItem.date_validite"
                          :rules="[
                            (v) =>
                              !v ||
                              editedItem.date_validite >=
                                editedItem.date_caution ||
                              'Date Incorrecte',
                          ]"
                          :edit="modify"
                          :date_max="null"
                        ></datepicker>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <datepicker
                          label="
                          Date Main Levée Prev.
                        "
                          v-model="editedItem.date_lib_ini"
                          :rules="[
                            (v) =>
                              !v ||
                              editedItem.date_validite >=
                                editedItem.date_lib_ini ||
                              'Date Incorrecte',
                          ]"
                          :edit="modify"
                          :date_max="null"
                        ></datepicker>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          autocomplete="off"
                          dense
                          ref="mnt"
                          type="number"
                          :label="'Montant'"
                          v-model.number="editedItem.montant"
                          :readonly="!modify"
                          :rules="[(v) => !!v || 'Montant obligatoire']"
                          hide-spin-buttons
                        />
                      </v-col>

                      <v-col cols="12" sm="2" md="2">
                        <v-autocomplete
                          dense
                          :items="currencies"
                          v-model="editedItem.currency_id"
                          item-text="name"
                          item-value="id"
                          label="Monnaie"
                          :readonly="!modify"
                          :rules="[(v) => !!v || 'Monnaie obligatoire']"
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-autocomplete
                          :items="agences_alg"
                          :item-value="'id'"
                          item-text="agence"
                          dense
                          v-model="editedItem.agence_id"
                          :rules="[(v) => !!v || 'Banque obligatoire']"
                          :label="'Banque'"
                          :readonly="!modify"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" v-if="tiertype == 1">
                        <v-text-field
                          autocomplete="off"
                          dense
                          ref="mnt"
                          type="number"
                          :label="'Taux Frais(%)'"
                          v-model.number="editedItem.taux"
                          :readonly="!modify"
                          :rules="[(v) => !!v || 'Taux Frais obligatoire']"
                          hide-spin-buttons
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="12">
                        <v-text-field
                          autocomplete="off"
                          dense
                          v-model="editedItem.comment"
                          label="Commentaire"
                          :readonly="!modify"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
                <v-tab-item :value="'2'">
                  <v-row>
                    <v-col cols="12" sm="6" md="12">
                      <filelist
                        :item="editedItem"
                        :key="fdocl"
                        :editer="editer"
                        :isform="false"
                        :doc_type="14"
                        :auth="auth"
                      >
                      </filelist>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container
              fill-height
              v-if="editedItem.statut_id == '16' && editedItem.montant >= 0"
            >
              <div>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="valid_caution"
                  v-if="valider && editedItem.statut_id == '16'"
                >
                  Valider
                </v-btn>
              </div>

              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>

            <v-spacer></v-spacer>

            <v-btn color="blue darken-1" text @click="save('16')" v-if="modify">
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>

    <template>
      <confirmdialog ref="confirm" />
    </template>
  </div>
</template>

<script>
import CREATE_CAUTION from "../graphql/Caution/CREATE_CAUTION.gql";
import UPDATE_CAUTION from "../graphql/Caution/UPDATE_CAUTION.gql";
import TIERS_CURSOR from "../graphql/Commande/TIERS_CURSOR.gql";

export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    filelist: () => import("../components/FileList.vue"),
    datepicker: () => import("./DatePicker.vue"),
    cursorselect: () => import("./CursorSelect.vue"),
  },
  name: "cautionform",
  props: {
    item: Object,
    items: Array,
    showForm: Boolean,
    tiertype: { type: Number, default: 1 },
    editer: Boolean,
    valider: Boolean,
    liberer: Boolean,
    agences: Array,
    cmds_list: Array,
    cautions_type: Array,
    currencies: Array,
  },
  data: () => ({
    drawer: null,
    tabs: null,
    espece: 0,

    fdocl: 1000,
    cs: 2000,
    progress: false,
    valid: true,
    modify: false,
    cmds_tier: [],
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    defaultItem: {
      id: -1,
      statut_name: "Brouillon",
      statut_id: "16",
      taux: 3,
    },
    editedItem: {},
    aff: 0,
    loading: false,
  }),

  computed: {
    variableadd() {
      let w = {
        TypeScope: this.tiertype == 1 ? 2 : 1,
        statut_id: [2, 3],
      };
      return w;
    },
    tierscursor() {
      return TIERS_CURSOR;
    },
    auth() {
      let a = this.tiertype == 1 ? "01080" : "01081";
      return a;
    },

    showcaution() {
      return this.showForm;
    },
    agences_alg() {
      let list = [];
      if (this.agences) {
        list = this.agences.filter((elm) => elm.fk_pays == 13);
      }
      return list;
    },
    formTitle() {
      return this.item.id < 0
        ? "Nouvelle Caution "
        : "Caution N° " + this.editedItem.ref_ca;
    },
  },
  watch: {},

  created() {},
  mounted() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.cs++;
        if (this.cmds_list)
          this.cmds_tier = this.cmds_list.filter(
            (elm) => elm.tier_id == this.editedItem.tier_id
          );
      } else {
        this.editedItem = Object.assign({}, this.defaultItem);
      }
    }
    this.modify = this.editer && this.editedItem.statut_id == "16";
  },

  methods: {
    cmd_change() {
      if (this.editedItem.id < 0) {
        let i = this.cmds_list.findIndex(
          (elm) => elm.id == this.editedItem.cmd_id
        );
        if (i >= 0) {
          this.editedItem.currency_id = this.cmds_list[i].currency_id
            ? this.cmds_list[i].currency_id
            : "114";
          let taux =
            this.editedItem.type_id == 3
              ? this.cmds_list[i].garantie
              : this.editedItem.type_id == 2
              ? this.cmds_list[i].avance
              : this.editedItem.type_id == 4
              ? this.cmds_list[i].pr
              : 0;

          this.editedItem.montant = parseFloat(
            ((this.cmds_list[i].mont_ttc * taux) / 100).toFixed(2)
          );
        }
      }
    },
    tier_change(item) {
      this.editedItem.tier_id = item;
      if (this.cmds_list)
        this.cmds_tier = this.cmds_list.filter(
          (elm) => elm.tier_id == this.editedItem.tier_id
        );
      if (this.cmds_tier)
        if (this.cmds_tier.length == 1 && this.editedItem.id < 0) {
          this.editedItem.cmd_id = this.cmds_tier[0].id;
          this.cmd_change();
        }
    },
    customFilter(item, queryText) {
      let textlist = [];
      let l =
        this.tiertype == 1
          ? ["code_client", "nom"]
          : ["code_fournisseur", "nom"];
      l.forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },

    async valid_caution() {
      if (this.$refs.form.validate()) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir valider cette caution?"
          )
        ) {
          this.save("17");
          this.close();
        }
      }
    },

    close() {
      this.$emit("close", this.editedItem);
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    datefr(date, time = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },

    async save(statut_id) {
      if (this.$refs.form.validate()) {
        this.editedItem.statut_id = statut_id;
        this.modify = this.editer && this.editedItem.statut_id == "16";

        if (this.editedItem.id > 0) {
          let v = {
            caution: {
              id: this.editedItem.id,
              type_id: this.editedItem.type_id,
              ref_ca: this.editedItem.ref_ca,
              tier_id: this.editedItem.tier_id,
              cmd_id: this.editedItem.cmd_id,
              date_caution: this.editedItem.date_caution,
              montant: parseFloat(this.editedItem.montant),
              taux: parseFloat(this.editedItem.taux),
              comment: this.editedItem.comment,
              statut_id: this.editedItem.statut_id,
              modepaiement: this.editedItem.modepaiement,
              agence_id: this.editedItem.agence_id,
              date_validite: this.editedItem.date_validite,
              date_lib_ini: this.editedItem.date_lib_ini,
              currency_id: this.editedItem.currency_id,

              write_uid: this.$store.state.me.id,
            },
          };
          this.maj(UPDATE_CAUTION, v, true);
          this.$emit("change", this.editedItem);
        } else {
          let v = {
            caution: {
              tiertype: this.tiertype,
              type_id: this.editedItem.type_id,
              ref_ca: this.editedItem.ref_ca,
              tier_id: this.editedItem.tier_id,
              cmd_id: this.editedItem.cmd_id,
              date_caution: this.editedItem.date_caution,
              montant: parseFloat(this.editedItem.montant),
              taux: parseFloat(this.editedItem.taux),
              comment: this.editedItem.comment,
              statut_id: this.editedItem.statut_id,
              modepaiement: this.editedItem.modepaiement,
              agence_id: this.editedItem.agence_id,
              date_validite: this.editedItem.date_validite,
              date_lib_ini: this.editedItem.date_lib_ini,
              currency_id: this.editedItem.currency_id,

              create_uid: parseInt(this.$store.state.me.id),
              write_uid: parseInt(this.$store.state.me.id),
            },
          };
          let r = await this.maj(CREATE_CAUTION, v, true);
          if (r) {
            this.editedItem.id = r.createCaution.id;
          }
          this.$emit("change", this.editedItem);
        }
        this.close();
      }
    },
  },
};
</script>
